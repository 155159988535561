<template>
  <Layout class="inner">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="search">
          <el-input
            v-model="formData.searchWord"
            clearable
            placeholder="名称/ID"
          />
        </el-form-item>
        <el-form-item prop="state">
          <el-select
            v-model="formData.status"
            placeholder="上架状态"
            clearable
          >
            <el-option
              v-for="(item, index) in appModuleStatesList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <el-button
          type="primary"
          @click="handleCreateAD"
        >
          新建
        </el-button>
      </template>
    </BHeader>
    <div class="data-main">
      <TypeManagement
        v-show="isOpen"
        class="types"
        :class="{ isOpen: isOpen }"
        @update="handleTypeUpdated"
      />
      <div
        class="table"
        :class="{ isOpen: isOpen }"
      >
        <el-table
          v-loading="loading"
          :data="listInfo.list"
          stripe
          border
          height="calc(100% - 96px)"
          style="width: 100%;"
        >
          <el-table-column
            prop="sort"
            label="排序"
            min-width="100"
          />
          <el-table-column
            prop="id"
            label="记录ID"
            min-width="100"
          />
          <el-table-column
            prop="adId"
            label="单元ID"
            min-width="100"
          />
          <el-table-column
            prop="title"
            label="名称"
            min-width="100"
          />
          <el-table-column
            prop="status"
            min-width="100px"
            label="上架状态"
          >
            <template v-slot="{ row }">
              {{ row.status === 1 ? "上架" : "下架" }}
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            width="180"
            label="操作"
          >
            <template v-slot="{ row }">
              <BTextButton
                title="编辑信息"
                icon="fa fa-pencil"
                @click="handleEditAD(row)"
              />
              <BTextButton
                :loading="row.loading"
                :title="row.status === 1 ? '启用' : '禁用'"
                :icon="`fa ${row.status === 1 ? 'fa-check-circle' : 'fa-ban'}`"
                @click="handleToggleAD(row)"
              />
              <BTextButton
                title="删除"
                icon="fa fa-trash-o"
                @click="handleDeleteAD(row)"
              />
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="listInfo.total"
          :page.sync="formData.pageNum"
          :limit.sync="formData.pageSize"
          @pagination="queryList"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import TypeManagement from '@/views/app/category/components/TypeManagement'
// import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
import {
  advertisementTagMap,
  appModuleStatesList
} from '@/utils/selectOptions'
import {
  deleteAD,

  getAdvertisingList,
  toggleAD

} from '@/api/APP_Category'

import { mapState } from 'vuex'
// import { pseudoRandom } from '@/utils'
// const idNext = pseudoRandom(7)
export default {
  name: 'ADList',
  components: { TypeManagement },
  data () {
    return {

      appModuleStatesList,
      advertisementTagMap,
      formData: {
        moduleId: '',
        pageNum: 1,
        pageSize: 20
      },
      currentType: {
        id: '',
        title: ''
      },
      isOpen: true,
      id: '',
      listInfo: {
        list: [],
        total: 0
      },
      loading: false,
      style: '',
      zoneId: ''
    }
  },
  computed: {
    ...mapState('app', ['appId', 'channel']),
    parentInfo () {
      let pId
      let name
      const currentType = this.currentType
      if (this.isOpen) {
        pId = currentType.id
        name = currentType.titleDetail.en
      } else {
        pId = this.$route.query.id
        name = this.$route.query.name
      }
      return {
        pId: pId,
        name: name
      }
    }
  },
  created () {
    const { id, style, zoneId } = this.$route.query
    this.zoneId = Number(zoneId)
    this.isOpen = Number(style) === 4
    this.formData.moduleId = id
    this.style = style
    if (!this.isOpen) {
      this.queryList()
    }
  },
  methods: {

    handleSearch () {
      this.formData.pageNum = 1
      this.queryList()
    },

    queryList () {
      this.loading = true
      const { pId } = this.parentInfo
      getAdvertisingList({
        pId,
        ...this.formData,
        appId: this.appId,
        channel: this.channel
      })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list
            this.listInfo.total = res.data.total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleTypeUpdated (obj) {
      this.currentType = obj
      this.formData.type = obj.id
      this.handleSearch()
    },
    handleCreateAD () {
      const { pId, name } = this.parentInfo
      const style = this.style
      this.$router.push({
        name: 'RealADCreate',
        query: { pId, name, style }
      })
    },
    handleEditAD (row) {
      const { pId, name } = this.parentInfo
      const style = this.style
      this.$router.push({
        name: 'RealADEdit',
        query: {
          id: row.id,
          pId,
          name,
          style
        }
      })
    },
    handleDeleteAD (row) {
      this.$confirm('此操作将删除, 是否继续', '提示', {
        type: 'warning '
      }).then(() => {
        this.$set(row, 'deleteLoading', true)
        deleteAD({
          id: row.id
        })
          .then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.queryList()
            }
          })
          .finally(() => {
            this.$set(row, 'deleteLoading', false)
          })
      })
    },
    handleToggleAD (row) {
      const TIPS =
        row.status !== 1
          ? '此操作将启用， 是否继续?'
          : '此操作将禁用， 是否继续?'
      const NEXT_STATUS = row.status !== 1 ? 1 : 2
      this.$confirm(TIPS, '提示', { type: 'warning ' }).then(() => {
        this.$set(row, 'loading', true)
        toggleAD({
          id: row.id,
          status: NEXT_STATUS
        })
          .then(res => {
            if (res.code === 200) {
              this.$set(row, 'status', NEXT_STATUS)
            }
          })
          .finally(() => {
            this.$set(row, 'loading', false)
          })
      })
    }
  }
}
</script>

<style lang="less">
.Layout {
  &.inner {
    min-width: 780px;
    .layout-content {
      height: calc(100vh - 130px);
      .el-table__body-wrapper {
        /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          background-color: transparent;
        }
        /*定义滚动条轨道 内阴影+圆角*/
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        /*定义滑块 内阴影+圆角*/
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: rgba(144, 147, 153, 0.3);
          transition: background-color 0.3s;
          &:hover {
            background-color: rgba(144, 147, 153, 0.5);
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="less">
.data-main {
  display: flex;
  height: calc(~"100% - 80px");
  > .types {
    &.isOpen {
      width: 150px;
      flex-shrink: 0;
    }
  }
  > .table {
    flex: 1;
    width: 100%;
    &.isOpen {
      width: calc(~"100% - 150px");
    }
  }
}
.game-cover {
  width: 96px;
  height: 50px;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
}
</style>
